.fileInput {
    background: white !important;
    color: #2c2c2c !important;
    cursor: pointer !important;
}

.inputTimeDiv {
    padding-left: 0 !important;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    align-items: baseline;
}
.inputHours {
    flex-grow: 50;
}
.inputDots {
    flex-grow: 1;
    padding: 0 6px;
}
.inputMinutes {
    flex-grow: 50;
}

@media(max-width: 767px) {
    .inputTimeDiv {
        padding-left: 15px !important;
    }
}