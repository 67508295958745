.tourUser {}
.tourMessage {}
.tourDelete {}
.tourSentAt {}

.messagesContent {
    padding: 0 30px 30px;
    position: relative;
}
@media screen and (max-width: 768px) {
    .messagesContent {
        padding-left: 15px !important;
        padding-right: 10px !important;
    }
}

.message {
    margin-bottom: 30px;
}
.sender {
    margin-bottom: 3px;
}

.messageContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: calc(100%);
    min-height: 40px;
    font-size: 1em;
    border-radius: 0 18px 18px 0;
    padding-left: 15px;
    margin-bottom: 5px;
    margin-left: -15px;
    background: white;
    box-shadow: 0 10px 16.2px 1.8px rgba(0, 0, 0, 0.05);
}

.messageText {
    margin: 5px 0;
}

.active {
    color: #E49E29;
}

.messageDelete {
    border: 0;
    background: transparent;
    padding: 0;
    margin: 0 15px 0 0;
    line-height: 0;
    cursor: pointer;
    outline: none;
}
.messageDelete:focus {
    outline: none;
}

.date {

}