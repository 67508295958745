.button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    vertical-align: middle;
    font-weight: bold;
    width: 100%;
    min-height: 58px;
}

.groupContainer {
    display: block;
    width: 100%;
    font-size: 14pt;
    border-radius: 27px;
    background: white;
    box-shadow: 0 10px 16.2px 1.8px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    padding-left: 10px;
}

.button > span {
    max-width: calc(100% - 20px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.button > span > i {
    font-size: .9em;
    transition: transform .35s ease;
}
.button[data-toggle="collapse"][aria-expanded="true"] > span > i {
    transform: rotate(90deg);
}

.groupName {

}

.groupCollapse {
    padding-bottom: 15px;
    padding-top: 15px;
}

.caretaker {

}