.title {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    font-size: 12pt;
    font-weight: normal;
    line-height: 1.5;
}

.icons {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: left;
    padding-right: 25px;
    margin-left: -4px;
}

.icons i {
    font-size: 18px;
}

.icons button {
    height: 28px !important;
    width: 25px !important;
    min-width: 25px !important;
    margin: 0 !important;
    box-shadow: none !important;
}

.editAvatar {
    background-size: cover;
    width: 100px;
    height: 100px;
}

.avatarLabel {
    display: block;
}

.avatarButtons {
    display: block;
}

.avatarButtons button {
    margin: 1px;
    width: 140px;
    padding: 8px;
}


@media (max-width: 767px) {
    .icons {
        justify-content: space-between;
    }

    .fileinput {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .fileinput .thumbnail {
        margin-bottom: 0;
        flex-grow: 0;
    }

    .avatarButtons {
        padding-left: 10px;
        flex-grow: 1;
    }

    .avatarButtons button {
        width: 100%;
    }
}

.avatar {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.locationName {
    max-width: calc(100% - 16px);
    display: inline-block;
}

.item {
    width: 100%;
    background: white;
    box-shadow: 0 10px 16.2px 1.8px rgba(0, 0, 0, 0.05);
    border-radius: 0 40px 40px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 74px;
    margin-bottom: 20px;
    padding-left: 25px;
}

.item a {
    color: inherit;
    text-decoration: none;
}

.item h5 {
    position: relative;
    cursor: pointer;
    margin-right: 24px;
}

.item .caret {
    float: right;
    margin-top: 4px;
}

.item p {
    margin-bottom: 0;
}

.item .card-title {
    margin-bottom: 0.25rem;
}

.item .card-title i {
    line-height: 1.5em;
    vertical-align: bottom;
}

.item .avatar {
    width: 5em;
    max-width: 5em;
    min-width: 5em;
    object-fit: cover;
}

.item .avatar .imgWrapper {
    width: 3.1em;
    height: 3.1em;
    border-radius: 50%;
    margin-bottom: 8px;
    box-shadow: 0 4px 16.2px 1.8px rgba(0, 0, 0, 0.22);
}

.item .avatar .imgWrapper img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.item .text {
    flex-grow: 2;
    padding-top: 3px;
}

.item .text .icons .btn {
    margin: 0 !important;
}

.item .add {
    width: 70px;
    min-width: 70px;
    border-radius: 0 34px 34px 0;
    background: #2E3C3F;
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.item .add > div {
    color: white;
    text-align: center;
    font-size: 1.4em;
    font-weight: 300;
    flex-grow: 1;
    line-height: 1;
    padding-bottom: 4px;
}

.machineIndicator {
    position: relative;
    border: 4px solid;
}
.machineIndicator:after {
    content: "";
    width: 4px;
    height: 13px;
    bottom: 0;
    position: absolute;
    left: 50%;
    transform: translate(-2px, 13px);
    border-radius: 2px;
}

.machineOffline {
    border-color: #EF0606;
}
.machineOffline:after {
    background: #EF0606;
}

.machineBad {
    border-color: #C89745;
}
.machineBad:after {
    background: #C89745;
}

.machineBelowAverage {
    border-color: #C89745;
}
.machineBelowAverage:after {
    background: #C89745;
}

.machineAverage {
    border-color: #C89745;
}
.machineAverage:after {
    background: #C89745;
}

.machineGood {
    border-color: #30b953;
}
.machineGood:after {
    background: #30b953;
}

.machineExcellent {
    border-color: #30b953;
}
.machineExcellent:after {
    background: #30b953;
}

.machineNone {
    border-color: #C4C9C3;
}
.machineNone:after {
    background: #C4C9C3;
}

.noConnection {
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 100%;
    font-size: 2.5em;
    text-align: center;
    color: #EF0606;
    border-radius: 50%;
}


.noMachines {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
}

.tourSettings {
}

.tourCalendars {
}

.tourPreview {
}

.tourList {

}