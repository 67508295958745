.title {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    font-size: 15pt;
    font-weight: normal;
    line-height: 1.5;
}
.tourSettings {}

.button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    vertical-align: middle;
    width: 100%;
    min-height: 58px;
    font-size: 14pt;
    border-radius: 0 27px 27px 0;
    padding-left: 30px;
    margin-bottom: 15px;
    background: white;
    box-shadow: 0 10px 16.2px 1.8px rgba(0, 0, 0, 0.05);
    cursor: pointer;
}

.button span {
    max-width: calc(100% - 30px);
}

.avatar {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.noItems {
    width: 100%;
    background: white;
    box-shadow: 0 10px 16.2px 1.8px rgba(0, 0, 0, 0.05);
    border-radius: 0 40px 40px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 44px;
    margin-bottom: 20px;
    padding-left: 25px;
    border-top: 1px solid #EF0606;
    border-right: 1px solid #EF0606;
    border-bottom: 1px solid #EF0606;
}

.item {
    width: 100%;
    background: white;
    box-shadow: 0 10px 16.2px 1.8px rgba(0, 0, 0, 0.05);
    border-radius: 0 40px 40px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 74px;
    margin-bottom: 20px;
    padding-left: 25px;
}

.item a {
    color: inherit;
    text-decoration: none;
}

.item .avatar {
    width: 4.25em;
    max-width: 4.25em;
    min-width: 4.25em;
    object-fit: cover;
}

.item .avatar .imgWrapper {
    width: 3.1em;
    height: 3.1em;
    border-radius: 50%;
    margin-bottom: 8px;
    box-shadow: 0 4px 16.2px 1.8px rgba(0, 0, 0, 0.22);
}

.item .avatar .imgWrapper img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.item .text {
    flex-grow: 2;
    padding-top: 3px;
}

.machineIndicator {
    position: relative;
    border: 4px solid;
}
.machineIndicator:after {
    content: "";
    width: 4px;
    height: 13px;
    bottom: 0;
    position: absolute;
    left: 50%;
    transform: translate(-2px, 13px);
    border-radius: 2px;
}

.machineOffline {
    border-color: #EF0606;
}
.machineOffline:after {
    background: #EF0606;
}

.machineBad {
    border-color: #C89745;
}
.machineBad:after {
    background: #C89745;
}

.machineBelowAverage {
    border-color: #C89745;
}
.machineBelowAverage:after {
    background: #C89745;
}

.machineAverage {
    border-color: #C89745;
}
.machineAverage:after {
    background: #C89745;
}

.machineGood {
    border-color: #30b953;
}
.machineGood:after {
    background: #30b953;
}

.machineExcellent {
    border-color: #30b953;
}
.machineExcellent:after {
    background: #30b953;
}

.noConnection {
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 100%;
    font-size: 2.5em;
    text-align: center;
    color: #EF0606;
    border-radius: 50%;
}


.noMachines {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
}

.tourName {

}
