.messagesContent {
    padding: 0 30px 30px;
    position: relative;
}

@media screen and (max-width: 768px) {
    .messagesContent {
        padding: 0 !important;
    }
}

.calendar {
    padding: 0 15px;
    font-size: 0.9em;
}

.dayNames {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    font-size: 8pt;
}

.dayName {
    text-transform: uppercase;
    text-align: center;
    width: 28px;
}

.emptyDay {
    content: "";
    width: 28px;
}

.monthNameRow {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 40px;
}

.monthEmpty {
    width: 28px;
}
.monthName {
    text-align: left;
    font-size: 12.5pt;
    width: calc(84px + ((100% - 196px)*(2/7)));
}

.month {
    padding: 5px;
    background: white;
    box-shadow: 0 1px 15px 1px rgba(39, 39, 39, 0.1);
    border-radius: 4px;
}

@media (max-width: 767px) {
    .month {
        background: transparent;
        padding: 0;
        border-radius: 0;
        box-shadow: none;
    }
}

.week {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    padding: 11px 0;
}

.day {
    width: 28px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    font-size: 12pt;
    font-weight: 300;
}

.dayIsToday {
    background-color: #E49E29 !important;
    color: #FFFFFF;
    border-radius: 50%;
}

.dayIsToday:after {
    content: "";
    background-color: #E49E29;
    width: 3px;
    height: 6px;
    display: block;
    border-radius: 1.5px;
    transform: translate(12px, -2px);
}

.dayHasItems {

}

.dayHasItems:after {
    content: "";
    background-color: #909090;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    display: block;
    position: absolute;
    z-index: 4;
    transform: translate(12px, -3px);
}
