.filterSelect::-ms-expand {
    display: none;
}
.filterSelect {
    width: 100%;
    padding: 0 !important;
    text-align: center;
    text-align-last: center;
    -webkit-appearance: none;
    appearance: none;
}
.actions {
    white-space: nowrap;
    text-align: right !important;
    padding: 0 !important;
}
.actionButton {
    margin: 0 !important;
}

@media(max-width: 768px) {
    .actions {
        white-space: normal !important;
        max-width: 40px !important;
    }
}
