.container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.container :global(.bootstrap-switch.wrapper) {
    min-width: 50px;
}
.extendedLabel {
    display: inline-block;
    margin-left: 5px;
    flex-grow: 2;
}