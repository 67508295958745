.title {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    font-size: 12pt;
    font-weight: normal;
    line-height: 1.5;
}

.avatar {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.item {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 74px;
    margin-bottom: 0;
    transition: opacity 0.5s;
}

.item a {
    color: inherit;
    text-decoration: none;
}

.item .avatar {
    width: 4.25em;
    max-width: 4.25em;
    min-width: 4.25em;
    object-fit: cover;
}

.item .avatar .imgWrapper {
    width: 3.1em;
    height: 3.1em;
    border-radius: 50%;
    margin-bottom: 8px;
    box-shadow: 0 4px 16.2px 1.8px rgba(0, 0, 0, 0.22);
}

.item .avatar .imgWrapper img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.item .text {
    flex-grow: 2;
    padding-top: 3px;
}

.machineIndicator {
    position: relative;
    border: 4px solid #79928d;
}
.machineIndicator:after {
    content: "";
    width: 4px;
    height: 13px;
    bottom: 0;
    position: absolute;
    left: 50%;
    transform: translate(-2px, 13px);
    border-radius: 2px;
    background: #79928d;
}

.notSelected {
    opacity: 0.5;
}

.tourName {

}
