.tourAvatar {

}
.tourRemove {
    text-transform: uppercase;
    margin-top: 24px;
}
.tourSave {
    margin-top: 45px !important;
}

.avatarFile {
    color: transparent;
    font-size: 1px;
    height: 34px;
    min-width: 150px;
    width: 100%;
}
.avatarFile::-webkit-file-upload-button {
    visibility: hidden;
}
.avatarFile::before {
    content: 'Add';
    display: inline-block;
    border-color: #6c757d;
    border-width: 1px;
    background-color: #B8BEB6;
    border-radius: 30px;
    padding: 8px 23px;
    cursor: pointer;
    margin: 1px;
    font-weight: 300;
    font-size: 11pt;
    line-height: 1.35em;
    color: #2E3C3F;
    margin-left: 15px;
    width: calc(100% - 30px);
    white-space: nowrap;
    text-align: center;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    box-shadow: 0 10px 16.2px 1.8px rgba(0, 0, 0, 0.05);
}
.avatarFile:hover::before {
/*    background-color: #979797;*/
}
.avatarFile:focus {
    outline: none;
}

.nl::before {
    content: 'FOTO KIEZEN'
}
.en::before {
    content: 'CHOOSE A PICTURE'
}
.de::before {
    content: 'WÄHLEN SIE EIN BILD'
}
.da::before {
    content: 'VÆLG ET BILLEDE'
}

.nlEdit::before {
    content: 'ANDERE FOTO KIEZEN'
}
.enEdit::before {
    content: 'CHOOSE A DIFFERENT PICTURE'
}
.deEdit::before {
    content: 'WÄHLEN SIE EIN ANDERES BILD'
}
.daEdit::before {
    content: 'VÆLG ET ANDET BILLEDE'
}
