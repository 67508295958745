.container {
    position: relative;
}
.overlay {
    position: absolute;
    z-index: 1000;
    background: rgba(228, 158, 41, 0.9);
    border-radius: 0 20px 20px 0;
    top: 100%;
    padding: 5px 15px 5px 15px;
    max-width: 90%;
}
.overlayTitle {
    position: absolute;
    z-index: 1000;
    background: rgba(228, 158, 41, 0.9);
    border-radius: 0 20px 20px 0;
    top: 100%;
    padding: 5px 15px 5px 15px;
    transform: translate3d(0, -20px, 0);
    max-width: 90%;
}
.overlayArrowContainer {
    position: absolute;
    display: flex;
    z-index: 999;
    top: 0;
    left: 0;
    width: 30px;
    height: 13px;
    overflow: hidden;
    transform: translate3d(0, -13px, 0);
}
.overlayArrowLeft {
    width: 12px;
    height: 25px;
    background-color: transparent;
    border-bottom-right-radius: 11px;
    box-shadow: 0 11px 0 0 rgba(228, 158, 41, 0.9);
    transform: translate3d(0, -12px, 0);
}
.overlayArrowRight {
    width: 12px;
    height: 25px;
    background-color: transparent;
    border-bottom-left-radius: 11px;
    box-shadow: 0 11px 0 0 rgba(228, 158, 41, 0.9);
    transform: translate3d(0, -12px, 0);
}
