.imageUpload {
    color: transparent;
    font-size: 1px;
    height: 34px;
    min-width: 150px;
    width: 100%;
}
.imageUpload::-webkit-file-upload-button {
    visibility: hidden;
}
.imageUpload::before {
    content: 'Add';
    display: inline-block;
    border-color: #6c757d;
    border-width: 1px;
    background-color: #888;
    cursor: pointer;
    margin: 1px;
    width: 100%;
    padding: 8px;
    border-radius: 30px;
    font-weight: 400;
    font-size: .8571rem;
    line-height: 1.35em;
    color: #fff;
    white-space: nowrap;
    text-align: center;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.imageUpload:hover::before {
    background-color: #979797;
}
.imageUpload:focus {
    outline: none;
}

.nl::before {
    content: 'Toevoegen'
}
.en::before {
    content: 'Add'
}
.de::before {
    content: 'Hinzufügen'
}
.da::before {
    content: 'Tilføj'
}
