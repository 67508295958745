.listItemsContainer {
}
.listItem {
    display: block;
    width: 100%;
    padding-bottom: 4px;
}
.listItemContent {
    width: 100%;
    font-size: 14px;
    padding: 4px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
}
.listItemTime {
}
.listItemName {
    font-weight: bold;
}
.listItemNextTime {

}

.appointment {
    position: relative;
    background: rgba(188, 140, 191, 0.7);
    color: #32004B;
}

.reminder {
    background: rgba(57, 107, 223, 0.7);
    color: #00206A;
}

.birthday {
    background: rgba(231, 90, 22, 0.7);
    color: #B44C00;
}

.tvshow {
    background: rgba(80, 170, 80, 0.7);
    color: #005826;
}