.previewContainer {
    position: relative;
    margin: 8px 0 0 -15px;
    width: calc(100% + 15px);
    padding-top: 56.25%;
    border-radius: 0 30px 30px 0;
    overflow: hidden;
}

.previewIframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border: 0;
    width: 100%;
    height: 100%;
    padding: 0;
}

.tourSentMessages {

}