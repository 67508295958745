.cell {
    text-overflow: initial;
    white-space: normal;
}
.actions {
    white-space: nowrap;
    padding: 0 !important;
    overflow: visible !important;
}
.actions button {
    width: 110px;
}
.actions > button {
    margin: 1px 0;
}
.actions :global(.collapse) button,
.actions :global(.collapsing) button
{
    padding: 6px;
    margin: 0 !important;
}

@media(max-width: 768px) {
    .cell {
        white-space: normal;
        overflow-wrap: break-spaces;
    }
}
