.button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    vertical-align: middle;
    width: 100%;
    min-height: 58px;
    font-size: 14pt;
    border-radius: 0 27px 27px 0;
    padding-left: 30px;
    margin-bottom: 15px;
    background: white;
    box-shadow: 0 10px 16.2px 1.8px rgba(0, 0, 0, 0.05);
    cursor: pointer;
}

.button span {
    max-width: calc(100% - 30px);
}

.tourEdit {}
.tourStatus {}