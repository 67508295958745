.date input {
    color: #000000 !important;
    cursor: pointer !important;
    background: transparent url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNiAxNiI+PHBhdGggZD0iTTEyLDJINEEyLDIsMCwwLDAsMiw0djhhMiwyLDAsMCwwLDIsMmg4YTIsMiwwLDAsMCwyLTJWNEEyLDIsMCwwLDAsMTIsMloiIHN0eWxlPSJmaWxsOiMyZTNjM2YiLz48cG9seWdvbiBwb2ludHM9IjguMDIgOC43MSA1Ljk5IDYuNzEgNS41NyA3LjE0IDguMDMgOS41NCAxMC40NSA3LjA4IDEwLjAxIDYuNjcgOC4wMiA4LjcxIiBzdHlsZT0iZmlsbDojZmZmIi8+PC9zdmc+) no-repeat !important;
    background-position-x: 100% !important;
    background-position-y: 50% !important;
    background-size: contain !important;
    box-shadow: none !important;
    width: 95% !important;
    margin: 0 !important;
    padding: 2px 2em 2px 0 !important;
    border: 0 !important;
    font-size: 1em;
    text-decoration: underline;
}

.date :global(.rdtPicker) {
    width: calc(100vw - 20px);
    max-width: 400px;
    border-radius: 6px;
}

.date :global(.form-control) {
    border-radius: 0 !important;
}

.time {
    display: inline-block;
    color: #000000 !important;
    cursor: pointer !important;
    background: transparent url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNiAxNiI+PHBhdGggZD0iTTEyLDJINEEyLDIsMCwwLDAsMiw0djhhMiwyLDAsMCwwLDIsMmg4YTIsMiwwLDAsMCwyLTJWNEEyLDIsMCwwLDAsMTIsMloiIHN0eWxlPSJmaWxsOiMyZTNjM2YiLz48cG9seWdvbiBwb2ludHM9IjguMDIgOC43MSA1Ljk5IDYuNzEgNS41NyA3LjE0IDguMDMgOS41NCAxMC40NSA3LjA4IDEwLjAxIDYuNjcgOC4wMiA4LjcxIiBzdHlsZT0iZmlsbDojZmZmIi8+PC9zdmc+) no-repeat !important;
    background-position-x: 100% !important;
    background-position-y: 50% !important;
    background-size: contain !important;
    box-shadow: none !important;
    width: 59px !important;
    margin: 0 !important;
    padding: 2px 2em 2px 0 !important;
    border: 0 !important;
    font-size: 1em;
    text-decoration: underline;
}

.timeDots {
    display: inline-block;
    margin: 0 4px;
}