.avatarFile {
    color: transparent;
    font-size: 1px;
    width: 100%;
    position: absolute;
    height: 44vw;
    top: 0;
    left: 0;
}
.avatarFile::-webkit-file-upload-button {
    visibility: hidden;
}

.avatarFile:hover::before {
    background-color: #979797;
}
.avatarFile:focus {
    outline: none;
}

.avatarCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.avatarContainer {
    border-radius: 50%;
    border: 1px solid rgba(0,0,0,0.22);
    overflow: hidden;
    display: inline-block;
    text-align: center;
    box-shadow: 0 4px 16.2px 1.8px rgba(0, 0, 0, 0.22);
    padding: 0;
    width: 44vw;
    height: 44vw;
}

.avatar {
    background-size: cover;
    width: 100%;
    height: 100%;
}