.tourScreenCode {
}
.tourScreenName {
}

.tourExisting {
}

.tourAvatar {
}

.tourName {
}

.tourBirthday {
}

.tourShowBirthday {
}

.tourAddress {
}

.tourZipCode {
}

.tourCity {
}

.tourCountry {
}

.tourProvince {
}

.imgContainer {
    text-align: center;
    margin-bottom: -10px;
}
.imgContainer img {
    width: 56vw;
    transform: translate(0, -10px);
}
.messageContainer {
    padding-bottom: 30px;
    font-size: 12.5pt;
}