.attachmentList {
    padding: 15px;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
}

.attachmentList > div {
    padding: 5px;
}
.attachmentList > div:nth-child(even) {
    background: #333;
}

.attachmentList > div:nth-child(odd) {
}