.counter {
    white-space: nowrap;
    width: 100px !important;
}

.machines {
    white-space: nowrap;
    width: 100px !important;
}

.cell {
    text-overflow: initial;
    white-space: normal;
}

.name {
    text-overflow: initial;
    white-space: normal;
}

.counter {
    white-space: nowrap;
    width: 100px !important;
}

.counterHeader {
    white-space: nowrap;
    width: 100px !important;
}

.machineHeader {
    width: 200px !important;
}

.filterSelect::-ms-expand {
    display: none;
}
.filterSelect {
    width: 100%;
    padding: 0 !important;
    text-align: center;
    text-align-last: center;
    -webkit-appearance: none;
    appearance: none;
}

.actions {
    white-space: nowrap;
    padding: 0 !important;
    overflow: visible !important;
    text-align: right !important;
}

.actions button {
    width: 110px;
}

.actions > button {
    margin: 1px 0;
}

.actions :global(.collapse) button,
.actions :global(.collapsing) button {
    padding: 6px;
    margin: 0 !important;
}

.actionButton {
    margin: 0 !important;
}

@media (max-width: 768px) {
    .cell {
        white-space: normal;
        overflow-wrap: break-spaces;
    }

    .machines {
        white-space: normal;
        overflow-wrap: break-spaces;
        width: 40px !important;
        max-width: 40px !important;
    }

    .counter {
        white-space: normal !important;
        overflow-wrap: break-spaces;
        width: 40px !important;
        max-width: 40px !important;
    }

    .counterHeader {
        white-space: normal !important;
        overflow-wrap: break-spaces;
        width: 40px !important;
        max-width: 40px !important;
    }

    .machineHeader {
        width: 80px !important;
        max-width: 80px !important;
    }

    .actions {
        white-space: normal !important;
        max-width: 40px !important;
    }
}
