.pages {
    touch-action: unset;
}

.htmlPage {
    overflow: auto;
    touch-action: pan-y;
}

.bodyPage {
    touch-action: pan-y;
}

.pageWrapper {
    position: relative;
    top: 0;
    min-height: 100vh;
    height: auto;
    display: flex;
    align-items: center;
}

.pageWrapper > div {
    width: 100%;
}