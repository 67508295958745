.tourMessage {}
.tourAppointment {}
.tourReminder {}
.tourTvProgram {}
.tourBirthday {}

.button {
    display: block;
    width: 100%;
    height: 58px;
    line-height: 58px;
    font-size: 14pt;
    border-radius: 0 27px 27px 0;
    padding-left: 30px;
    margin-bottom: 14px;
    background: white;
    box-shadow: 0 10px 16.2px 1.8px rgba(0, 0, 0, 0.05);
    cursor: pointer;
}