.containerStretcher {
    min-height: calc(100vh - 250px);
}

.timeline {
    align-items: stretch;
    display: flex;
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 0;
    position: relative;
    text-rendering: geometricPrecision;
    width: 100%;
}

.hourContainer {
    display: block;
    position: relative;
    width: 40px;
}

.hour {
    padding-right: 6px;
    text-align: right;
    position: relative;
}

.hourSpan {
    top: -8px;
    position: relative;
    font-size: 8pt;
    font-weight: 400;
    line-height: 11px;
    display: block;
}

.timelineContent {

}

.hourLineContainer {
    display: block;
    position: absolute;
    width: 100%;
}

.hourLine {
}

.hourLine:after {
    content: '';
    border-bottom: 1px solid #aaa;
    position: absolute;
    width: calc(100% - 40px);
    z-index: 3;
    pointer-events: none;
    margin-top: -1px;
}

.wholeDayContainer {
    padding-bottom: 5px;
    margin-bottom: 20px;
    margin-top: -40px;
    margin-left: -10px;
    width: calc(100% + 20px);
}

.wholeDayTitle {
    display: block;
    font-size: 13px;
}

.wholeDayItem {
    width: 100%;
/*
    margin-bottom: 2px;
    border-radius: 4px;

 */
    padding: 6px;
    font-size: 12px;
    line-height: 14px;
    background: #2E3C3F;
    color: white;
}

.itemContainer {
    position: absolute;
    z-index: 5;
    top: 0;
    width: calc(100% - 40px);
}

.item {
    display: block;
    position: absolute;
    z-index: 4;
    width: 100%;
    padding: 0 1px;
}

.isBeforeNow {
    opacity: 0.5;
}

.itemContent {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 4px;
    padding: 2px;
    font-size: 10px;
}

.listItemsContainer {
}
.listItem {
    display: block;
    width: 100%;
    padding-bottom: 4px;
}
.listItemContent {
    width: 100%;
    font-size: 14px;
    padding: 4px;
    border-radius: 4px;
    display: flex;
}
.listItemTime {
    white-space: nowrap;
    width: 100px;
    min-width: 100px;
}
.listItemName {

}

.appointment {
    position: relative;
    background: rgba(188, 140, 191, 0.7);
    color: #32004B;
}

.reminder {
    background: rgba(57, 107, 223, 0.7);
    color: #00206A;
}

.birthday {
    background: rgba(231, 90, 22, 0.7);
    color: #B44C00;
}

.tvshow {
    background: rgba(80, 170, 80, 0.7);
    color: #005826;
}