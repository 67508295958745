.select {
    border-radius: 0;
    background: transparent;
    box-shadow: none;
    border: 0;
    text-decoration: underline;

    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNiAxNiI+PHBhdGggZD0iTTEyLDJINEEyLDIsMCwwLDAsMiw0djhhMiwyLDAsMCwwLDIsMmg4YTIsMiwwLDAsMCwyLTJWNEEyLDIsMCwwLDAsMTIsMloiIHN0eWxlPSJmaWxsOiMyZTNjM2YiLz48cG9seWdvbiBwb2ludHM9IjguMDIgOC43MSA1Ljk5IDYuNzEgNS41NyA3LjE0IDguMDMgOS41NCAxMC40NSA3LjA4IDEwLjAxIDYuNjcgOC4wMiA4LjcxIiBzdHlsZT0iZmlsbDojZmZmIi8+PC9zdmc+);
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 50%;
    background-size: contain;
    width: auto !important;
    padding: 2px 2em 2px 15px !important;
    font-size: 1em;
}

.select:focus {
    background: transparent;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNiAxNiI+PHBhdGggZD0iTTEyLDJINEEyLDIsMCwwLDAsMiw0djhhMiwyLDAsMCwwLDIsMmg4YTIsMiwwLDAsMCwyLTJWNEEyLDIsMCwwLDAsMTIsMloiIHN0eWxlPSJmaWxsOiMyZTNjM2YiLz48cG9seWdvbiBwb2ludHM9IjguMDIgOC43MSA1Ljk5IDYuNzEgNS41NyA3LjE0IDguMDMgOS41NCAxMC40NSA3LjA4IDEwLjAxIDYuNjcgOC4wMiA4LjcxIiBzdHlsZT0iZmlsbDojZmZmIi8+PC9zdmc+);
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 50%;
    background-size: contain;

    border: 0;
    outline: none !important;
    box-shadow: none !important;
}

.inlineSelect {
    display: inline-block;
}
