.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.day {
    width: 36px;
    height: 36px;
    text-align: center;
    line-height: 39px;
    border-radius: 50%;
    padding: 0;
    text-transform: uppercase;
    transition: all 0.5s;
    font-size: 11pt;
    font-weight: 300;
}
.day:after {
    content: "";
    width: 4px;
    height: 8px;
    border-radius: 2px;
    display: block;
    transform: translate(16px, -4px);
    transition: all 0.5s;
}

.daySelected {
    background-color: #E49E29 !important;
    color: #FFFFFF !important;
}

.daySelected:after {
    background-color: #E49E29 !important;
}