.themeSelect {
    line-height: 1;
    /*width: 60%;*/
    min-width: 205px;
    display: inline-block;
}

:global(.themeSelectPrefix) {
}
:global(.themeSelectPrefix__control) {
    border-radius: 0 !important;
    box-shadow: none !important;
    border: 0 !important;
    text-decoration: underline !important;

    background: transparent url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNiAxNiI+PHBhdGggZD0iTTEyLDJINEEyLDIsMCwwLDAsMiw0djhhMiwyLDAsMCwwLDIsMmg4YTIsMiwwLDAsMCwyLTJWNEEyLDIsMCwwLDAsMTIsMloiIHN0eWxlPSJmaWxsOiMyZTNjM2YiLz48cG9seWdvbiBwb2ludHM9IjguMDIgOC43MSA1Ljk5IDYuNzEgNS41NyA3LjE0IDguMDMgOS41NCAxMC40NSA3LjA4IDEwLjAxIDYuNjcgOC4wMiA4LjcxIiBzdHlsZT0iZmlsbDojZmZmIi8+PC9zdmc+) no-repeat !important;
    background-position-x: 100% !important;
    background-position-y: 50% !important;
    background-size: contain !important;
    width: auto !important;
    padding: 2px 2em 2px 0 !important;
    font-size: 1em !important;
    min-height: auto !important;

}
:global(.themeSelectPrefix__indicators) {
    display: none !important;
}
:global(.themeSelectPrefix__value-container) {
    line-height: 1;
    padding: 0 !important;
}
:global(.themeSelectPrefix__single-value) {
    margin: 0 !important;
    text-decoration: underline;
}

.tourGreeting {
}

.tourTheme {
    display: flex;
    flex-direction: row;
}
.tourTheme > label:after {
    content: ": ";
    display: inline-block;
    padding-right: 0.5em;
}

.tourClockType {
}

.tourHoursType {
}

.tourFont {
}

.tourDAT {
}

.tourLanguage {
}

.tourFadeSpeed {
}

.tourAlertSound {
}

.tourTomorrowDay {
}

.tourItIsToday {
}

.tourShowHour {
}

.tourShowCountdown {

}

.tourShowWeather {
}

.tourTomorrowWeather {
}

.tourPublicHolidaysShow {
}

.tourPublicHolidaysShowBefore {
}

.tourPublicHolidaysCountry {
}
.tourSave {

}