.tableItems tr td:first-child {
    font-weight: bold;
    white-space: nowrap;
}
.tableItems tr td:nth-child(2) {
    width: 100%;
}

.filterSelect::-ms-expand {
    display: none;
}
.filterSelect {
    width: 100%;
    padding: 0 !important;
    text-align: center;
    text-align-last: center;
    -webkit-appearance: none;
    appearance: none;
}
.actions {
    white-space: nowrap;
    text-align: right !important;
    padding: 0 !important;
}
.actionButton {
    margin: 0 !important;
}