.bottomPadding {
    width: 100%;
    display: block;
}

.barContent {
    padding: 0 20px 40px 20px;
    position: relative;
    font-size: 12.5pt;
}

.barGradient {
    display: none;
    pointer-events: none;
}

.bottomBar {
    background: #DADFD9;
    width: 100%;
    margin-top: -10px;
}
.bottomBar > button {
    font-weight: bold;
}

.bottomBar .backButton {
    display: block;
    color: #2e3c3f;
    text-decoration: underline;
    background: transparent;
    font-weight: 600;
    border: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
    margin-top: 15px;
}

.bottomBar .backButton:focus {
    outline: none;
    background: transparent;
}

.bottomBar .link {
    display: block;
    color: #2e3c3f;
    text-decoration: underline;
    background: transparent;
    font-weight: 300;
    border: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
    margin-top: 15px;
}

.bottomBar .link:focus {
    outline: none;
    background: transparent;
}

.nomoveBottomBar {
    padding: 0 20px;
    margin-top: -10px;
}
.nomoveBottomBar > button {
    font-weight: bold;
}

.helpButton {
    position: absolute;
    font-size: 21px;
    right: 20px;
    bottom: 10px;
    cursor: pointer;
}

.addButton {
    border: 0;
    background: #2e3c3f;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    width: 45px;
    height: 45px;
    transform: translate(-50%, 0);
    top: 0;
    padding: 0;
    color: white;
    line-height: 50px;
    text-align: center;
    font-size: 8pt;
}
.addButton i {
    transform: translate(0px, -1px);
}
.addButton:after {
    content: "";
    background-color: #2e3c3f;
    width: 4px;
    height: 8px;
    border-radius: 2px;
    display: block;
    transform: translate(20px, -7px);
}

@media(max-width: 767px) {
    .bottomBar {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        margin-top: 0;
        z-index: 100;
    }
    .barGradient {
        position: absolute;
        display: block;
        top: 0;
        width: 100%;
        height: 8px;
        background: linear-gradient(0deg, rgba(128,133,127,0.21) 0%, rgba(128,133,127,0) 100%);
        transform: translate(0, -8px);
    }
    .barContent {
        padding-bottom: max(env(safe-area-inset-bottom, 20px), 20px);
    }
}